import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from 'app/app.service';
import { AuthenticationService } from 'app/components/authentication.service';
import { ReCaptchaV3Service, RecaptchaComponent } from 'ng-recaptcha';
import { loginDetails } from './loginDeatils.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('reCaptcha') reCaptcha: RecaptchaComponent ;

  loginForm: FormGroup
  reCaptchaStr: string;
  siteKey: string;
  // obj: { username: any; password: any; gRecaptchaResponse: any; project: string; };
obj = {} as loginDetails
  message: any;


  constructor(private authservice: AuthenticationService,private recaptchaV3Service: ReCaptchaV3Service,public router: Router, ){ }
  public resolved(captchaResponse: string) {
    this.reCaptchaStr = captchaResponse;
    if(this.reCaptchaStr){
      this.submitOpt();
    }
}
  ngOnInit(): void {
   this.siteKey="6LcDiyIaAAAAANApNwBlP3oI7EO5S1qrsKlYDrbU"

  this.initializeloginForm()

  
  }
  initializeloginForm(){
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [ Validators.required,]),
      recaptcha: new FormControl('',Validators.required),
    })
  }
  onLoginClick(captchaRef: any) :void{
    if(this.reCaptchaStr){
      captchaRef.reset()
    }else{
      captchaRef.execute()
    }
  }
  submitOpt(){
    this.message=''
    
    this.obj={
      "username":this.loginForm.get('username').value,
      "password":this.loginForm.get('password').value,
      "gRecaptchaResponse":this.reCaptchaStr,
      "project": "GOF"
      // "password":'admin',
      // "email":'password',
    }
    // console.log(obj,"dd")
    this.authservice.login(this.obj).subscribe((response: any) => {
      if(response.status==="success")
     { 
       console.log(response.token)
     this.router.navigate(['/vp-report'])
     localStorage.setItem('userToken', response.token)

     }else{
      this.message="Invalid Login Details"
      this.loginForm.reset();
     }
      },)
  }

}
