import { Component, OnInit } from '@angular/core';

declare const $: any;

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  icontype: string,
  childrens?: ChildrensItems[];
}
export interface ChildrensItems {
  path: string;
  title: string;
  type?: string;
  ab: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
      path: '/report',
      title: 'Reports',
      type: 'sub',
      icontype: 'analytics ',
      collapse: 'tables',
      children: [
          {path: 'vp-report',type:'subsx', icontype: 'shopping_bag', title: 'Virtual products'},
          {path: 'routePlanner',type:'subsx', icontype: 'place',  title: 'Route Planner'},
          {path: 'refferals',type:'subsx', icontype: 'redeem',  title: 'Rewards and Referrals'},
          {path: 'commands',type:'subsx', icontype: 'cached',  title: 'CMD'},
          // {path: 'StockReport',type:'subsx', icontype: 'library_books',  title: 'Stock Report'},
          // {path: 'Stock-20',type:'subsx', icontype: 'library_books',  title: 'Stock < 20'},

      {path: 'custom-report',type:'subs', icontype: 'supervised_user_circle ', title: 'Customers',  
      collapse: 'maps',
          childrens: [ 
            { path: 'custom-report',type:'subs', title: 'Custom', ab:'CR'},
            {path: 'lastMonthAll', title: 'Last 30 Days All', ab:'LMA'},
            {path: 'lastMonthOneTime', title: 'Last 30 Days Once', ab:'LMO'},
            // {path: 'routePlanner', title: 'Route Planner'},
      ]},
      {path: 'Stock-report',type:'subs', icontype: 'library_books', title: 'Stock',  
      collapse: 'Stock',
          childrens: [ 
            { path: 'StockReport',type:'subs', title: 'Stock All', ab:'SA'},
            {path: 'Stock-20', title: 'Stock Below 20', ab:'S20'},
            {path: 'StockHistory' ,title:'Stock History', ab:'SH'},
            // {path: 'lastMonthOneTime', title: 'Last 30 Days Once', ab:'LMO'},
            // {path: 'routePlanner', title: 'Route Planner'},
      ]}
      ]
  },
  // {path: 'routePlanner',type:'link', icontype: 'place',  title: 'Route Planner'},
  // {
  //     path: '/maps',
  //     title: 'Account',
  //     type: 'link',
  //     icontype: 'place',
  //    },
  // {
  //     path: '/widgets',
  //     title: 'Widgets',
  //     type: 'link',
  //     icontype: 'widgets'

  // },{
  //     path: '/charts',
  //     title: 'Charts',
  //     type: 'link',
  //     icontype: 'timeline'

  // },{
  //     path: '/calendar',
  //     title: 'Calendar',
  //     type: 'link',
  //     icontype: 'date_range'
  // },{
  //     path: '/pages',
  //     title: 'Pages',
  //     type: 'sub',
  //     icontype: 'image',
  //     collapse: 'pages',
  //     children: [
  //         {path: 'pricing', title: 'Pricing', ab:'P'},
  //         {path: 'timeline', title: 'Timeline Page', ab:'TP'},
  //         {path: 'login', title: 'Login Page', ab:'LP'},
  //         {path: 'register', title: 'Register Page', ab:'RP'},
  //         {path: 'lock', title: 'Lock Screen Page', ab:'LSP'},
  //         {path: 'user', title: 'User Page', ab:'UP'}
  //     ]
  // }
];
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
