import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
// import { headers } from './../Models/Response/alertDetailsResponse';
// import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { config } from 'process';
import { ActivatedRoute, Router } from '@angular/router';
import { vpOrders, vpReport } from './components/models/vpReport.model';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private APIEndpoint = environment.APIEndpoint
  constructor (
    handler: HttpBackend,
    private route: ActivatedRoute,
    private router: Router,public http: HttpClient
    ) { }

    public getVpReport(): Observable<any> {
      return this.http.get<any>(`${this.APIEndpoint}/report/vp`,)
    }
    // public getStokeReport(obj:any): Observable<any> {
    //   console.log(obj)
    //   return this.http.get<any>(`${this.APIEndpoint}/report/stock-report`,obj)
    // }
    public getAddressList(): Observable<any> {
      return this.http.get<any>(`${this.APIEndpoint}/order-details`,)
    }
    public getCustomerReport(obj): Observable<any> {
      return this.http.post<any>(`${this.APIEndpoint}/report/customer`,obj)
    }
    // public getVpReportPrint(obj): Observable<any> {
    //   return this.http.post(`${this.APIEndpoint}/report/vp-down`,obj,{responseType:'blob' as 'blob' })
     
    // }
    public getStartEnd(){
      return this.http.get(`${this.APIEndpoint}/user/configuration`)
    }

    public configuration(): Observable<any> {
      return this.http.get<any>(`${environment.APIEndpoint}/key`,)
    }
    public getVpReportPrint(obj: vpReport): Observable<any> {
      let url =`${this.APIEndpoint}/report/vp-down`;
      return this.http.post(url, obj, {
        responseType: "blob",
        headers: new HttpHeaders().append("Content-Type", "application/json")
      });
    }
    public getCustomerReportPrint(obj: vpReport): Observable<any> {
      let url =`${this.APIEndpoint}/report/customer-down`;
      return this.http.post(url, obj, {
        responseType: "blob",
        headers: new HttpHeaders().append("Content-Type", "application/json")
      });
    }
    public getStokeReportPrint(obj: any): Observable<any> {
      let url =`${this.APIEndpoint}/report/stock-report-down`;
      return this.http.post(url, obj, {
        responseType: "blob",
        headers: new HttpHeaders().append("Content-Type", "application/json")
      });
    }
    public getReportPrint(obj:any):Observable<any>{
      let url=`${this.APIEndpoint}/report/customer-reward-points-down`
      return this.http.post(url,obj,{
        responseType:"blob",
        headers:new HttpHeaders().append("content-Type","application/json")
      });
    }
    public getGlobalSave(obj: any): Observable<any> {
      // let url =`${this.APIEndpoint}/report/stock-report-down`;
      // return this.http.post(url, obj, {
      //   responseType: "blob",
      //   headers: new HttpHeaders().append("Content-Type", "application/json")
      // });
      return this.http.post(`${this.APIEndpoint}/report/re-stock`,obj )

    }
    public getStokeReport(obj: any): Observable<any> {
      return this.http.post(`${this.APIEndpoint}/report/stock-report`,obj )
    }
    public getRewardReport(obj:any):Observable<any>{ 
     return this.http.post(`${this.APIEndpoint}/report/customer-reward-points-search`,obj)
    }
    public getDbSyncStatus(): Observable<any> {
      return this.http.get(`${this.APIEndpoint}/command/dbsyncstatus`)
    }
    public execCommand(obj: any,commnd:any): Observable<any> {
      return this.http.post(`${this.APIEndpoint}/command/execute?website=${obj}&command=${commnd}`,{ responseType:'blob' as 'blob' })
    }

    public getStockHistory(obj:any): Observable<any> {
      return this.http.post<any>(`${this.APIEndpoint}/report/stock-history`,obj)
    }
    public postStartAndEndPoints(obj:any): Observable<any> {
      return this.http.post<any>(`${this.APIEndpoint}/user/save-start-and-endpoints?startPoint=${obj.StartPoint}&endPoint=${obj.endPoint}`,'')
    }
    public getSHprint(obj:any): Observable<any> {
      let url = `${this.APIEndpoint}/report/stock-history-down`;
      return this.http.post(url , obj,{
        responseType: "blob",
        headers: new HttpHeaders().append("Content-Type", "application/json")
      });
    }
  
}
