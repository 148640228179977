import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { loginDetails } from 'app/login/loginDeatils.model';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(private http: HttpClient,) {
}
  public login(obj: loginDetails): Observable<any> {
    return this.http.post<any>(`${environment.APIEndpoint}/user/login`, obj)
  }
  updateToken(token) {
    if (token && token.length) {
        localStorage.setItem('userToken', token)
    }
}
isLoggedIn() {
  if ( localStorage.getItem('userToken')) {
      return true
  } else {
      return false
  }
}
}
